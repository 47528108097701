import * as eva from 'eva-icons';
import { Power2, Power4, TweenMax } from 'gsap';
import React, { useEffect, useState } from 'react';
import Alert from 'react-bootstrap/Alert';
import ApiSignupModal from './ApiSignupModal';
import dash from './asset/img/dash.png';
import data from './asset/img/data.png';


function Home({ name }) {

  useEffect(() => {
    eva.replace()
  }, [])
  const [success, setSuccess] = useState(false);
  if (window.location.hash === '#success') {
    setSuccess(true);
    setTimeout(() => setSuccess(false), 5000);
    window.location.hash = '';
  }
  const [show, setShow] = useState(false)
  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)
  const [stripeError, setStripeError] = useState('')
  const [t, setT] = useState(0)

  useEffect(() => {
    var container = document.getElementById('container');
    var graphMeasurement = document.getElementById('graph-measurement');
    
    var allCircles = document.getElementsByTagName('circle');
    var allLines = document.getElementsByTagName('line');
    
    var destArray = [15,52,28,170,105,93,44, 122, 179, 170, 220];
    
    TweenMax.set(allCircles, {
      attr:{fill:'rgb(179, 106, 243)', r:6},
      transformOrigin:'50% 50%',
      scale:0
    })
    TweenMax.set([allLines], {
      attr:{stroke:'#18B5DD',  r:3},
      drawSVG:'100% 100%',
      strokeWidth:3
    })
    TweenMax.set([graphMeasurement], {
      attr:{stroke:'transparent'},
      drawSVG:'100% 100%',
      strokeWidth:1
    })
    
    TweenMax.set([allCircles, allLines], {
      y:'+=300'
    })
    
    TweenMax.set(graphMeasurement, {
      y:'+=280',
      alpha:0.3
    })
    TweenMax.to(graphMeasurement,3, {
      drawSVG:'0% 100%',
      delay:1,
        ease:Power2.easeInOut
    })
    TweenMax.set('svg', {
      alpha:1
    })
    for(var i = 0; i < allCircles.length; i++){
      
      TweenMax.to(allCircles[i], 2, {
        attr:{cy:'-=' + destArray[i]},
        onUpdate:moveLines,
        onUpdateParams:[i],
        delay:i/5,
        ease:Power4.easeInOut
      })
        if(allLines[i]){
          
          TweenMax.to(allLines[i], 1, {
            drawSVG:'400',
            delay:i/5,
            ease:Power4.easeInOut
          })     
        }
    
        TweenMax.to(allCircles[i], 1, {
         scale:1,
          delay:i/5,
        ease:Power4.easeInOut
       })
      
    }
    
    function moveLines(i){
    
      if(allLines[i]){
    
        TweenMax.set(allLines[i], {
           attr:{
             x2:allCircles[i].getAttribute('cx'), y2:allCircles[i].getAttribute('cy')
           }     
         })
       TweenMax.set(allLines[i], {
           attr:{
             x1:allCircles[i+1].getAttribute('cx'), y1:allCircles[i+1].getAttribute('cy')
           }     
         })   
        
    
    }
    }
    setT(1)
  }, [])

  return (
    <div>
    <a id="home" position={{ top: 0, position: 'absolute' }}></a>
    <ApiSignupModal show={show} handleClose={handleClose} />
    <div className="App">
      {success && <Alert variant="success">Success! we will be in contact shortly</Alert>}
      <section className="section-one" style={{ display: 'flex', alignItems: 'center', width: '100%', position: 'relative' }}>

      {/* <div style={{ width: '100%', position: 'absolute', top: 20, right: 20, zIndex: 2 }}>
        <a href="#api"><h5 className="header-links">Get Started</h5></a>
      </div> */}
    <div
    id="bg-round"
    className="background-animation"
      style={{
        position: 'absolute',
        zIndex: 1,
        width: '1750px',
        height: '1750px',
        top: '-1100px',
        transform: 'translateX(-4%)',
        background: 'rgba(255,255,255, 0.1)',
        borderRadius: '100%',
        overflow: 'hidden',
      }}
    />        
    <div className="container">
      <div className="row" style={{ position: 'relative' }}>
    <div id="title-holder" style={{ textAlign: 'left', display: 'flex', justifyContent: 'start', marginTop: '-10%' }}>

          <div style={{ marginTop: -50 }}>
            <div style={{ height: 150 }}>
          <h1 id="title">
            Disaster <bold><span className="text-bg-red">SAFE</span></bold> <div style={{ height: 100, display: 'inline' }}><i id="top-logo" data-eva="pin-outline" data-eva-fill="white" data-eva-height="100" data-eva-width="100"></i></div>
          </h1>
          </div>
          <div id="subtitle" >
            Natural disaster data and alerting
            <br/>
           Easy to consume API
          </div>
          <a href="https://dashboard.disastercheckin.app/signup" ><h5 className="header-links">Get Started</h5></a>
          </div>
          <div></div>
          <div
          id="phone-background"
          style={{
            height: 400,
            width: 400,
            borderRadius: 400,
            fontWeight: 600,
            marginTop: 50,
            right: -50,
            top: 0,
            transform: 'translate(-25%, -50%)',
            position: 'absolute',
            zIndex: 3
          }}
        >
          <div style={{
            opacity: 1,
            background: 'linear-gradient(45deg, white, #ffffff47)',
            padding: '10px'
          }}>
            <div style={{ opacity: t }}>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 741 450">
<defs>
</defs>
<line fill="none" stroke="#4AC900" stroke-width="4" stroke-miterlimit="10" x1="77" y1="11" x2="10" y2="11"/>
<line fill="none" stroke="#4AC900" stroke-width="4" stroke-miterlimit="10" x1="171" y1="11" x2="77" y2="11"/>
<line fill="none" stroke="#4AC900" stroke-width="4" stroke-miterlimit="10" x1="238" y1="11" x2="171" y2="11"/>
<line fill="none" stroke="#4AC900" stroke-width="4" stroke-miterlimit="10" x1="292" y1="11" x2="238" y2="11"/>
<line fill="none" stroke="#4AC900" stroke-width="4" stroke-miterlimit="10" x1="367" y1="11" x2="292" y2="11"/>
<line fill="none" stroke="#4AC900" stroke-width="4" stroke-miterlimit="10" x1="466" y1="11" x2="367" y2="11"/>
<line fill="none" stroke="#4AC900" stroke-width="4" stroke-miterlimit="10" x1="512" y1="11" x2="466" y2="11"/>
<line fill="none" stroke="#4AC900" stroke-width="4" stroke-miterlimit="10" x1="588" y1="11" x2="511" y2="11"/>
<line fill="none" stroke="#4AC900" stroke-width="4" stroke-miterlimit="10" x1="645" y1="11" x2="588" y2="11"/>
<line fill="none" stroke="#4AC900" stroke-width="4" stroke-miterlimit="10" x1="731" y1="11" x2="645" y2="11"/>
<g>
	<circle fill="#FF8300" cx="10.5" cy="10.5" r="10.5"/>
	<circle fill="#FF8300" cx="77.5" cy="10.5" r="10.5"/>
	<circle fill="#FF8300" cx="171.5" cy="10.5" r="10.5"/>
	<circle fill="#FF8300" cx="238.5" cy="10.5" r="10.5"/>
	<circle fill="#FF8300" cx="292.5" cy="10.5" r="10.5"/>
	<circle fill="#FF8300" cx="367.5" cy="10.5" r="10.5"/>
	<circle fill="#FF8300" cx="466.5" cy="10.5" r="10.5"/>
	<circle fill="#FF8300" cx="510.5" cy="10.5" r="10.5"/>
	<circle fill="#FF8300" cx="588.5" cy="10.5" r="10.5"/>
	<circle fill="#FF8300" cx="645.5" cy="10.5" r="10.5"/>
	<circle fill="#FF8300" cx="730.5" cy="10.5" r="10.5"/>
</g>
<path id="graph-measurement" fill="none" stroke="#741E00" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
	M731,127H10 M10,127v-18 M113,127v-9.1 M731,109v18 M216,127v-9.1 M319,127v-9.1 M422,127v-9.1 M525,127v-9.1 M628,127v-9.1"/>
</svg>
</div>
          </div>
          <img src={data} width="350" style={{
            marginTop: -50,
            transform: 'translateX(-60px)',
            // border: '1px solid rgba(255,255,255,0.5)',
            boxShadow: '25px 15px rgba(255,255,255,0.5)'
          }}/>
          {/* <img src={graph} width="300" style={{
            marginTop: -700,
            transform: 'translateX(160px)',
            // border: '1px solid rgba(255,255,255,0.5)',
            boxShadow: '5px 10px rgba(255,255,255,0.5)'
          }}/> */}
        </div>
        </div>
        </div>
        </div>
      </section>

      <section className="section-two">
      <div className="container">
        <div className="row" style={{ position: 'relative' }}>
        </div>
        <div className="row">
          <div className="col-md-4">
            <div className="section-two-logo">
            <i data-eva="shake-outline" data-eva-fill="#3366FF" data-eva-height="130" data-eva-width="130"></i>
            </div>
          <h4>
            Stay ahead of disasters
          </h4>
          <p>
            Disaster SAFE retrieves and aggregates reliable and up to date data sources to keep your organisation ahead of any natural or man made disasters. 
          </p>
        </div>
        <div className="col-md-4">
          <div className="section-two-logo">
            <i data-eva="share-outline" data-eva-fill="#3366FF" data-eva-height="130" data-eva-width="130"></i>
          </div>
          <h4>
          Alert your users
          </h4>
          <p>
          Use Disaster SAFE check in features to ensure your users don't miss critical alerts.
          </p>
        </div>
        <div className="col-md-4">
          <div className="section-two-logo">
          <i data-eva="layers-outline" data-eva-fill="#3366FF" data-eva-height="130" data-eva-width="130"></i>
          </div>
          <h4>
          Gain insight
          </h4>
          <p>
          Use historical disaster data to inform your business and your customers of disaster associated risks.
          </p>
        </div>
      </div>
      </div>
    </section>

      <section className="section-three">
        <div className="container">
        <div className="row">
          <div className="col-md-6">
          <h1 id="mission">Our mission</h1>
          <hr id="mission-underline" />
          <h5 id="mission-statement">
            To give every person across the world access to early warning data for natural disasters.
          </h5>
          <div style={{ textAlign: 'left', marginLeft: 65, marginTop: 50 }}>
          {/* <button
          style={{
            backgroundColor:'#6772E5',
            color:'#FFF',
            padding: '8px 12px',
            border: 0,
            borderRadius: '4px',
            fontSize:'1.2em'
          }}
          id="checkout-button-sku_H8KvwHkOxiG4eY"
          role="link"
          onClick={() => {
            const stripe = window.Stripe('pk_live_88Bo8zyBkQYMElnVyHj5NwX500FP0TKz27')
            stripe.redirectToCheckout({
              items: [{sku: 'sku_H8KvwHkOxiG4eY', quantity: 1}],
              successUrl: 'https://disastercheckin.app',
              cancelUrl: 'https://disastercheckin.app'
            })
            .then((result) => {
              if (result.error) {
                setStripeError(result.error.message)
              }
            })
          }}
        >
          Support us
        </button> */}
        <div style={{ backgroundColor: 'grey', fontSize: 15, margin: 10, borderRadius: '4px' }}>{stripeError}</div>
        </div>
          </div>
          <div className="col-md-6" style={{ position: 'relative' }}>
            <div style={{ backgroundColor: 'rgba(205,205,255,0.2)', position: 'absolute', height: '100%', width: '100%' }}></div>
            <div id="cf4a" style={{ position: 'relative', height: 600 }}>
            <img src={'https://images.unsplash.com/photo-1572989994359-ae5afc3a3efe?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=634&q=80'} width="100%" style={{ height: 600, position: 'absolute', width:450, objectFit: 'cover' }} />
            <img src={'https://images.unsplash.com/photo-1552664730-d307ca884978?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=2850&q=80'} width="100%" style={{ height: 600, position: 'absolute', width:450, objectFit: 'cover' }} />
            <img src={'https://images.unsplash.com/photo-1584033376655-760c4de3f483?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=724&q=80'} width="100%" style={{ height: 600, position: 'absolute', width:450, objectFit: 'cover' }} />
            <img src={'https://images.unsplash.com/photo-1528489932140-a8be341a50d1?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=752&q=80'} width="100%" style={{ height: 600, position: 'absolute', width:450, objectFit: 'cover' }} />
            </div>
          </div>
        </div>
        </div>
      </section>

    <section className="section-four">
      <a id="api"/>
      <div className="container">
      <div className="row" >
      <img src={dash} id="graph1" />
        <div className="col-md-10" style={{ marginTop: '10%' }}>
        <code style={{ fontSize: 30, color: 'white' }}>Get <code style={{ color: 'skyblue' }}>REALTIME</code> natural disaster data<br/><code style={{ fontSize: 32 }}>Webhooks</code> and <code style={{ fontSize: 32 }}>RESTful API</code></code>
        <p>Access global disaster alerts to plug into your product.</p>
        <a href="https://dashboard.disastercheckin.app/signup" style={{ border: 'none', padding: '10px', backgroundColor: 'rgb(230, 240, 255)' }}>Get started</a>
        <div style={{ marginTop: '25%' }}>
        <code className="code-2" style={{ fontSize: 30, marginLeft: '59%', color: 'white'}}>...increase.<code style={{ color: 'skyblue' }}>Safety</code></code>
        <p className="code-2" style={{  marginLeft: '59%' }}>We commit to providing a free service for non-profits.</p>
        {/* <a i href="https://dashboard.disastercheckin.app/signup" style={{ marginLeft: '59%', border: 'none', padding: '10px', backgroundColor: 'rgb(230, 240, 255)' }}>Get started</a> */}
        </div>
        </div>
      </div>
      </div>
    </section>

    </div>
    </div>
  );
}

export default Home;
