import React from 'react';
import { HashLink as Link } from 'react-router-hash-link';

const Footer = ({ name }) => (
  <footer>
  <div className="container">
  <div className="row">
    {/* <hr style={{}}/> */}
    <div className="col-6">
      <h6>United Kingdom</h6>
      <h6 style={{ marginTop: 20 }}>England</h6>
      <h6 style={{ marginTop: 60 }}>© {name} {new Date().getFullYear()}</h6>
      <a href="https://rabbit-island.co"><h6 style={{ marginTop: 20 }}>Rabbit Island LTD</h6></a>
    </div>
    
    <div className="col-6" style={{ textAlign: 'right' }}>
    <Link to="/#home" ><h6 style={{ textAlign: 'right' }}>Home</h6></Link>
    <a href="https://dashboard.disastercheckin.app" ><h6 style={{ textAlign: 'right', marginTop: 20 }}>Login</h6></a> 
    <a href="mailto:team@disastercheckin.app" target="_blank" rel="noopener" ><h6 style={{ textAlign: 'right', marginTop: 20  }}>Contact</h6></a>
    <Link to="/terms#top" ><h6 style={{ marginTop: 20 }}>Terms of Service</h6></Link>
    <Link to="/privacy#top"><h6 style={{ marginTop: 20 }}>Privacy Policy</h6></Link>
    </div>
  </div>
  </div>
  </footer>
)

export default Footer