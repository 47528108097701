import React from 'react';

const TermsService = () => (
  <div className="container" style={{ marginTop: 100, marginBottom: 50 }}>
    <a id="top"></a>
    <div className="row" style={{ marginLeft: 0, marginRight: 0 }}>
      <div className="col-xs-12">
<h4 style={{ marginTop: 30, marginBottom: 30 }}>Disaster SAFE App Terms of Service</h4>
<p>1. Terms</p>

<p>By accessing the website and mobile application services of Disaster SAFE app, you are agreeing to be bound by these terms of service, all applicable laws and regulations, and agree that you are responsible for compliance with any applicable local laws. If you do not agree with any of these terms, you are prohibited from using or accessing this site. The materials contained in this website are protected by applicable copyright and trademark law.</p>

<p>2. Use License</p>

<p>Permission is granted to temporarily download one copy of the materials (information or software) on Disaster SAFE's website for personal, non-commercial transitory viewing only. This is the grant of a license, not a transfer of title, and under this license you may not: </p>

<p>modify or copy the materials; use the materials for any commercial purpose, or for any public display (commercial or non-commercial); attempt to decompile or reverse engineer any software contained on Disaster SAFE's website; remove any copyright or other proprietary notations from the materials; or transfer the materials to another person or &quot;mirror&quot; the materials on any other server. This license shall automatically terminate if you violate any of these restrictions and may be terminated by Disaster SAFE at any time. Upon terminating your viewing of these materials or upon the termination of this license, you must destroy any downloaded materials in your possession whether in electronic or printed format.</p>

<p>3. Disclaimer</p>

<p>The materials and services provided on Disaster SAFE's website and mobile application are provided on an 'as is' basis. Disaster SAFE makes no warranties, expressed or implied, and hereby disclaims and negates all other warranties including, without limitation, implied warranties or conditions of merchantability, fitness for a particular purpose, or non-infringement of intellectual property or other violation of rights. Further, Disaster SAFE does not warrant or make any representations concerning the accuracy, likely results, or reliability of the use of the materials or services on its website or otherwise relating to such materials or on any sites linked to this site.</p>

<p>4. Limitations</p>

<p>In no event shall Disaster SAFE or its suppliers be liable for any damages (including, without limitation, damages for loss of data or profit, or due to business interruption) arising out of the use or inability to use the materials on Disaster SAFE's website or mobile software, even if Disaster SAFE or a Disaster SAFE authorized representative has been notified orally or in writing of the possibility of such damage.</p>
<p>INFORMATION YOU MAY RECEIVE VIA THE SERVICES OR MOBILE SOFTWARE WEBSITES MAY NOT BE RELIED UPON BY YOU FOR PERSONAL, SECURITY, MEDICAL, LEGAL OR FINANCIAL DECISIONS AND YOU SHOULD CONSULT AN APPROPRIATE PROFESSIONAL FOR SPECIFIC ADVICE TAILORED TO YOUR SITUATION.</p>
<p>Rabbit Island Ltd and Disaster SAFE MAKE NO REPRESENTATIONS ABOUT THE SUITABILITY, RELIABILITY, AVAILABILITY, TIMELINESS, AND ACCURACY OF THE SERVICES OR THE MOBILE SOFTWARE WEBSITES FOR ANY PURPOSE OR THE AVAILABILITY OF ANY NETWORKS OR COMMUNICATIONS LINES OR FUNCTIONING OF ANY MOBILE PHONE OR DEVICE NECESSARY FOR THE SERVICES.</p>
<p>We try to keep Disaster SAFE up, bug-free, and safe, but you use it at your own risk. It is not a replacement for or supplement to your own safety precautions, news alerts and official advice.</p>

<p>5. Accuracy of materials</p>

<p>The materials appearing on Disaster SAFE's website could include technical, typographical, or photographic errors. Disaster SAFE does not warrant that any of the materials on its website or mobile software are accurate, complete or current. Disaster SAFE may make changes to the materials contained on its website or mobile software at any time without notice. However Disaster SAFE does not make any commitment to update the materials.</p>

<p>6. Links</p>

<p>Disaster SAFE has not reviewed all of the sites linked to its website or mobile software and is not responsible for the contents of any such linked site. The inclusion of any link does not imply endorsement by Disaster SAFE of the site. Use of any such linked website or mobile software is at the user's own risk.</p>

<p>7. Modifications</p>

<p>Disaster SAFE may revise these terms of service for its website or mobile software at any time without notice. By using this website or mobile software you are agreeing to be bound by the then current version of these terms of service.</p>

<p>8. Governing Law</p>

<p>These terms and conditions are governed by and construed in accordance with the laws of United Kingdom and you irrevocably submit to the exclusive jurisdiction of the courts in that State or location. </p>
</div>
</div>
</div>)

export default TermsService;
