import React from 'react';
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import './App.css';
import Footer from './Footer';
import Home from './Home';
import PrivacyPolicy from './PrivacyPolicy';
import TermsService from './TermsService';

const name = 'Disaster SAFE'

const App = () => (
  <Router>
    <Switch>
    <Route path="/privacy" component={PrivacyPolicy} />
    <Route path="/terms" component={TermsService} />
    <Route path="*" component={() => <Home name={name} />} />
    </Switch>
    <Footer name={name}/>
  </Router>
)

export default App;
